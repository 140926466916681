'use strict'

const animationsFactory = require('./animationsFactory')
const tweenEngineGreenSock = require('./tweenEngineGreenSock')

/**
 * Constructor for a local tween engine.
 * @returns {{factory: {animate: (exports.animate|*), transition: (exports.transition|*), sequence: (exports.sequence|*), registerAnimation: *, registerTransition: *, getProperties: (exports.getProperties|*), getAnimationsDefs: *, getTransitionsDefs: *}, engine: {timeline: *, tween: (exports.tween|*), set: exports.set, kill: (exports.kill|*), addTickerEvent: (exports.addTickerEvent|*), removeTickerEvent: (exports.removeTickerEvent|*), isTweening: exports.isTweening, getBoundingRect: exports.getBoundingRect, getBoundingContentRect: *, delayedCall: (exports.delayedCall|*), animateTimeScale: (exports.animateTimeScale|*)}}}
 * @constructor
 * @class core.animations.tweenEngine
 */
function create(tweenMax, TimelineMax) {
    /** core.animations.tweenEngineGreenSock */
    const engine = tweenEngineGreenSock.create(tweenMax, TimelineMax)

    /** core.animationsFactory */
    const factory = animationsFactory.create()

    return {
        factory,
        engine
    }
}

module.exports = {
    create
}

