'use strict'
/*eslint no-console:0*/
const log = {error: (...args) => console.error(...args)} //require('../core/log')

/**
 * returns an animation factory
 * @returns {{sequence: (function(*=): SequenceBuilder), resetRegistrations: resetRegistrations, getTransitionsDefs: (function(): (defs.transitions|{})), registerAnimation: registerAnimation, getProperties: (function(string): (*|{})), getAnimationsDefs: (function(): (defs.animations|{})), animate: animate, registerTransition: registerTransition, transition: transition, getAllProperties: (function(): (defs.properties|{}))}}
 */
function create() {
    const defs = {
        animations: {},
        transitions: {},
        properties: {}
    }

    /**
     * Start a new sequence
     * NOTE: expects for an animation called 'BaseSequence' to be registered
     * @param [params]
     * @constructor
     */
    function SequenceBuilder(params) {
        const baseSequence = 'BaseSequence' // I have to use a var or ESLint will be mad.
        this.timeline = defs.animations[baseSequence](params ? Object.assign({}, params) : {})
    }

    /**
     * Add another animation, transition or sequence to the sequence
     * @param {TweenMax|TimelineMax|Array} tweens
     * @param {String|number} [position='+=0']
     * @param {String|number} [align='normal']
     * @returns {SequenceBuilder}
     */
    SequenceBuilder.prototype.add = function (tweens, position, align) {
        position = typeof position === 'undefined' ? '+=0' : position
        align = align || 'normal'
        this.timeline.add(tweens, position, align)
        return this
    }

    /**
     * Get the real timeline attached to the sequence
     * @returns {TimelineMax}
     */
    SequenceBuilder.prototype.get = function () {
        return this.timeline
    }

    /**
     * Add/remove Timeline event handler
     * From GSAP Docs (https://greensock.com/docs/v3/GSAP/Timeline/eventCallback()):
     * .eventCallback( type:String, callback:Function, params:Array ) : [Function | self]
     * Gets or sets an event callback like onComplete, onUpdate, onStart, onReverseComplete, or onRepeat
     * along with any parameters that should be passed to that callback.
     *
     * @param {'onComplete'|'onUpdate'|'onStart'|'onReverseComplete'|'onRepeat'} type
     * @param {function|null} handler function for adding and null for removing
     * @param {*[]} [args] rest params
     * @return {SequenceBuilder}
     */
    SequenceBuilder.prototype.event = function (type, handler, ...args) {
        this.timeline.eventCallback(type, handler, ...args)
        return this
    }


    /**
     * Play the timeline
     * @return {SequenceBuilder}
     */
    SequenceBuilder.prototype.play = function () {
        this.timeline.play()
        return this
    }

    /**
     * Play the timeline backwards
     * @return {SequenceBuilder}
     */
    SequenceBuilder.prototype.reverse = function () {
        this.timeline.reverse()
        return this
    }

    /**
     * Pause the timeline
     * @return {SequenceBuilder}
     */
    SequenceBuilder.prototype.pause = function () {
        this.timeline.pause()
        return this
    }

    /**
     * Seek the timeline
     * @return {SequenceBuilder}
     */
    SequenceBuilder.prototype.seek = function (progress) {
        this.timeline.totalProgress(progress)
        return this
    }

    /**
     * Get the real timeline attached to the sequence
     * @return {SequenceBuilder}
     */
    SequenceBuilder.prototype.clear = function () {
        this.timeline.clear()
        return this
    }

    /**
     * Instantiate a new SequenceBuilder
     * @variation 1
     * @param [params]
     * @returns {SequenceBuilder}
     */
    function sequence(params) {
        return new SequenceBuilder(params)
    }

    /**
     * Schedule an animation on an element
     * @variation 2
     * @param {String} name The name of the animation (ie. "FadeIn")
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} [duration=1] Animation Duration in seconds
     * @param {Number} [delay=0] The time to wait in seconds before starting the animation
     * @param {Object} [params] Additional parameters for the animation
     * @returns {TweenMax|TimelineMax|null} The animation function to schedule, null if this animation is not registered
     */

    function animate(name, elements, duration, delay, params) {
        const animationsDef = defs.animations[name]
        if (!animationsDef) {
            log.error('Warning:', name, 'is not a registered animation. skipping.')
            return null
        }
        return animationsDef(elements, duration, delay, params ? Object.assign({}, params) : {})
    }

    /**
     * Schedule an transition animation between two elements
     * The difference from "animate" is that "transition" functions receives two elements and animated between them.
     * @variation 2
     * @param {String} name The name of the animation (ie. "FadeIn")
     * @param {Array<HTMLElement>|HTMLElement} sourceElements DOM element to animate from
     * @param {Array<HTMLElement>|HTMLElement} destinationElements DOM element to animate to
     * @param {Number} [duration=1] Animation Duration in seconds
     * @param {Number} [delay=0] The time to wait in seconds before starting the animation
     * @param {Object} [params] Additional parameters for the animation
     * @returns {TimelineMax|null} The animation function to schedule, null if this animation is not registered
     */
    function transition(name, sourceElements, destinationElements, duration, delay, params) {
        const transitionsDef = defs.transitions[name]
        if (!transitionsDef) {
            log.error('Warning:', name, 'is not a registered transition. skipping.')
            return null
        }
        return transitionsDef(sourceElements, destinationElements, duration, delay, params ? Object.assign({}, params) : {})
    }

    /**
     * This function is used by animation and transition classes to register themselves on this animations instance
     * @param {string} animationName The public name of the animation which it will be called with
     * @param {function} animationFunc The animation function
     * @param {{}} animationProperties The animation properties
     */
    function registerAnimation(animationName, animationFunc, animationProperties) {
        if (defs.transitions[animationName]) {
            log.error('Warning: there is already a transition with the name', animationName)
        }
        defs.animations[animationName] = animationFunc
        defs.properties[animationName] = animationProperties || {}
    }

    /**
     * API Sugar. currently does the same as registerAnimation
     * This function is used by animation and transition classes to register themselves on this animations instance
     * @param {string} transitionName The public name of the animation which it will be called with
     * @param {function} transitionFunc The transition function
     * @param {{}} animationProperties The transition properties
     */
    function registerTransition(transitionName, transitionFunc, animationProperties) {
        if (defs.animations[transitionName]) {
            log.error('Warning: there is already an animation with the name', transitionName)
        }
        defs.transitions[transitionName] = transitionFunc
        defs.properties[transitionName] = animationProperties
    }

    /**
     * @typedef {object} AnimationProperties
     * @property {array<string>} groups        - Groups (or tags) this animation or transition is assigned to
     * @property {boolean} [hideOnStart]       - Animations only: Flag notating if this animation should hide the element before starting
     * @property {number} [defaultDuration]    - Transitions only: the default transition duration
     */
    /**
     * Get special properties for the passed animation or transition name
     * @param {string} name
     * @returns {AnimationProperties}
     */
    function getProperties(name) {
        return defs.properties[name] || {}
    }

    function getAllProperties() {
        return defs.properties
    }

    function getAnimationsDefs() {
        return defs.animations
    }

    function getTransitionsDefs() {
        return defs.transitions
    }

    function resetRegistrations() {
        defs.animations = {}
        defs.transitions = {}
        defs.properties = {}
    }

    /**
     * @class core.animationsFactory
     */
    return {
        animate,
        transition,
        sequence,
        registerAnimation,
        registerTransition,
        getProperties,
        getAllProperties,
        getAnimationsDefs,
        getTransitionsDefs,
        resetRegistrations
    }
}

module.exports = {
    create
}
